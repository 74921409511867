/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { TheSeo } from "x"

const SecondPage = () => (
	<Fragment>
		<TheSeo title="Page two" />
		<h1>Hi from the second page</h1>
		<p>Welcome to page 2</p>
		<Link to="/">Go back to the homepage</Link>
	</Fragment>
)

export default SecondPage
